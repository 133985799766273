module controllers {
    export module applicationcore {
        interface IUserScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class userProfileCtrl {

            static $inject = ['$scope', '$rootScope', '$q', '$uibModal','$cookies', 'generalService', 'localeService', 'userAccountService', 'eventTypeService', 'bsLoadingOverlayService',"ediCusresStatusService","interfaceClientService",'$timeout'];

            currentUser: interfaces.applicationcore.IUser;
            localeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            eventList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            ownerEntityId: number;

            gridApi: uiGrid.IGridApi;

            enableResetPassword: boolean = true;

            constructor(
                private $scope: IUserScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $cookies: ng.cookies.ICookiesService,
                public generalService: interfaces.applicationcore.IGeneralService,
                public localService: interfaces.applicationcore.ILocaleService,
                public userService: interfaces.applicationcore.IUserAccountService,
                public eventTypeService: interfaces.applicationcore.IEventTypeService,
                public bsLoadingOverlayService,
                public ediCusresStatusService: interfaces.integration.IEDICusresStatusService,
                public interfaceClientService: interfaces.integration.IInterfaceClientService,
                public $timeout: ng.ITimeoutService,) {  
                    
               
                this.loadControls();
                
                bsLoadingOverlayService.wrap({
                    referenceId: "us.userLoad"
                }, () => {
                    var loadPromises: ng.IPromise<any>[] = [];

                    loadPromises.push(this.LoadUser());
                    loadPromises.push(this.LoadLocale());
                    return $q.all(loadPromises);
                });
            }

            CheckCanResetPassword(){
                var token = this.$cookies.get("userToken");
                var tokenIssuer: string = "";                
                const splitStrings = token.split(".");

                if (splitStrings.length > 0) {
                    var decodedString = atob(splitStrings[1]);
                    var part = angular.fromJson(decodedString);
                    tokenIssuer = part.iss;
                }

                if (tokenIssuer !== "GTS") {
                    this.enableResetPassword = false;
                }
            }

            gvwUserEvents: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi); },
                columnDefs: [{
                    name: "EVENT",
                    displayName: "Event Type",
                    field: "Description",
                    enableFiltering: false,
                    enableSorting: false,
                    enableColumnMenu: false,
                    enableCellEdit: false,
                    width: 300
                }, {
                        name: "Reference",
                        displayName: "Reference",
                        field: "Reference",
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 300
                    }, {
                        name: "inclusionFilter",
                        displayName: "Inclusion Filter",
                        field: "inclusionFilter",
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 200
                    }, {
                        name: "exclusionFilter",
                        displayName: "Exclusion Filter",
                        field: "exclusionFilter",
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 200
                    },
                    {
                        name: "DASHCOMM",
                        displayName: "Dashboard",
                        field: "Dasboard",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        enableColumnMenu: false,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.userProfileCtrl.checkBoxChanged(row.entity)">',
                    }, {
                        name: "EMAILCOMM",
                        displayName: "Email",
                        field: "Email",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        enableColumnMenu: false,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.userProfileCtrl.checkBoxChanged(row.entity)">',
                   
                    }, {
                        name: "includePayload",
                        displayName: "Include XML",
                        field: "includePayload",
                        width: 110,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        enableColumnMenu: false,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.userProfileCtrl.checkBoxChanged(row.entity)">',
                   
                    }]
            };

            checkBoxChanged(entity) {
                if (this.gridApi) {
                    this.gridApi.rowEdit.setRowsDirty([entity]);
                }
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.gridApi.selection.selectRow(gridSelection.row.entity);
                });
            }

            loadControls() {
            }

            LoadUser() {
                return this.userService.loadUserProfile().get((result: interfaces.applicationcore.IUser) => {
                    this.currentUser = result;
                    this.gvwUserEvents.data = result.EventUserSubscriptions;

                }, (errorResult) => {
                        this.generalService.displayMessageHandler(errorResult.data);
                    }).$promise;
            }

            LoadLocale() {
                return this.localService.getLocale().query((resultList) => {
                    this.localeList = resultList;
                }, (errorResult) => {
                    this.generalService.displayMessageHandler(errorResult.data);
                }).$promise;
            }

            reset() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: "us.userLoad"
                }, () => {
                    return this.userService.loadUserProfile().get((result) => {
                        this.currentUser = result;
                    }).$promise;
                    });

                
            }

            formSubmit() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: "us.userLoad"
                }, () => {
                    return this.Save();
                });
            }

            Save() {
                this.currentUser.EventUserSubscriptions = [];

                if (this.gridApi && this.gridApi.rowEdit.getDirtyRows().length > 0) {
                    this.currentUser.EventUserSubscriptions = _.map(this.gridApi.rowEdit.getDirtyRows(), (n) => {
                        return n.entity;
                    });
                }

                this.currentUser.Id = null;
                this.currentUser.UserId = null;

                return this.userService.saveUserProfile().save(this.currentUser, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(result);

                    if (this.gridApi && !result.HasErrorMessage) {
                        this.gridApi.rowEdit.setRowsClean(this.currentUser.EventUserSubscriptions);
                    }

                    this.bsLoadingOverlayService.wrap({
                        referenceId: "us.userLoad"
                    }, () => {
                        return this.LoadUser();
                    });
                }, (errorResult) => {
                        this.generalService.displayMessageHandler(errorResult.data);
                    }).$promise;
            }

            removeEventUserSubscriptionClick(){
                var selectedRows = <Array<interfaces.applicationcore.IEventUserSubscriptionDisplay>>this.gridApi.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].EventUserSubscriptionId || selectedRows[0].EventUserSubscriptionId === 0) {
                    this.gridApi.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwUserEvents.data)).splice((<Array<any>>(this.gvwUserEvents.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the User Event Subscription?").then((result: boolean) => {
                    if (result) {
                        this.removeEventUserSubscription(selectedRows[0].EventUserSubscriptionId);
                    }
                });                
            }

            removeEventUserSubscription(eventUserSubscriptionId: number){
                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'us.userLoad'
                },
                    () => {
                        return this.userService.removeEventUserSubscription(eventUserSubscriptionId).delete((data) => {
                            this.generalService.displayMessageHandler(data);
                            this.LoadUser();
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });

            }


            addEventUserSubscriptio(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Add Event Subscription</h3></div>
                                <div class="modal-body"> 
                                <div class="gts-padding">
                                <fieldset>
                                              
                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <req></req>
                                        <label>Event Type</label>
                                    </div>
                                    <div class="col-md-8">
                                        <gts-dropdown  name="eventType" ng-model="addNewCtrl.eventType"   load-data="addNewCtrl.loadEventTypes()" required="true"></gts-dropdown>
                                    </div>
                                </div>

                                <div class="row vertical-align" ng-if="addNewCtrl.eventType.Id === 31">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>EDI Cusres Status</label>
                                    </div>
                                    <div class="col-md-8">
                                        <gts-dropdown  name="cusresStatus" ng-model="addNewCtrl.cusresStatus"   load-data="addNewCtrl.loadCusresStatuses()" required="false"></gts-dropdown>
                                    </div>
                                </div>

                                <div class="row vertical-align" ng-if="addNewCtrl.eventType.Id === 51">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Specific Interface Client</label>
                                    </div>
                                    <div class="col-md-8">
                                        <gts-dropdown  name="interfaceClient" ng-model="addNewCtrl.interfaceClient"   load-data="addNewCtrl.loadInterfaceClients()" required="false"></gts-dropdown>
                                    </div>
                                </div>

                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Inclusion Filter</label>
                                    </div>
                                    <div class="col-md-7">
                                        <p class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="addNewCtrl.inclusionFilter" />
                                               
                                        </p> 
                                    </div>     
                                    <div class="col-md-1">
                                        <i class="fa fa-question-circle"
                                            uib-tooltip='The filters will include/exclude any events that contain the text in the filters. If both are maintained they will work in conjunction.'
                                            tooltip-placement="right">
                                        </i>                                    
                                    </div>
                                </div>

                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Exclusion Filter</label>
                                    </div>
                                    <div class="col-md-7">
                                        <p class="input-group-sm">
                                            <input type="text" class="form-control" ng-model="addNewCtrl.exclusionFilter" />
                                        </p> 
                                    </div>   
                                    <div class="col-md-1">
                                        <i class="fa fa-question-circle"
                                            uib-tooltip='The filters will include/exclude any events that contain the text in the filters. If both are maintained they will work in conjunction.'
                                            tooltip-placement="right">
                                        </i>                                    
                                    </div>                                                                    
                                </div>                                
                                
                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Email Communication</label>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="input-group-sm has-feedback" ng-class="{'has-error': showAll.$invalid}">
                                            <input type="checkbox" name="showAllName" ng-model="addNewCtrl.emailCommunication"/>
                                        </p>
                                    </div>
                                </div>             
                                
                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Dashboard Communication</label>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="input-group-sm has-feedback" ng-class="{'has-error': showAll.$invalid}">
                                            <input type="checkbox" name="showAllName" ng-model="addNewCtrl.dashboardCommunication"/>
                                        </p>
                                    </div>
                                </div>        

                                <div class="row vertical-align">
                                    <div class="col-md-4">
                                        <opt></opt>
                                        <label>Include Event XML</label>
                                    </div>
                                    <div class="col-md-3">
                                        <p class="input-group-sm has-feedback" ng-class="{'has-error': showAll.$invalid}">
                                            <input type="checkbox" name="includeEventXML" ng-model="addNewCtrl.includePayload"/>
                                        </p>
                                    </div>
                                </div>                                                                

                                </fieldset>
                                </div>
                                </div>
                                <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.eventType || !(addNewCtrl.emailCommunication || addNewCtrl.dashboardCommunication )" ng-click="addNewCtrl.add()">Add</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                </div>
                               
                    `,
                    controller: class addNewCtrl {
                        
                        eventUserSubscription: interfaces.applicationcore.IEventUserSubscriptionDisplay;

                        eventType : interfaces.applicationcore.IDropdownModel;
                        cusresStatus: interfaces.applicationcore.IDropdownModel;
                        interfaceClient: interfaces.applicationcore.IDropdownModel;
                        emailCommunication: boolean = false;
                        dashboardCommunication: boolean = false;
                        includePayload: boolean = false;
                        inclusionFilter: string;
                        exclusionFilter: string;
                      
                        loadPromises: ng.IPromise<any>[];

                        disabled : boolean = false;

                        constructor(   
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private eventTypeService: interfaces.applicationcore.IEventTypeService,
                            private ediCusresStatusService: interfaces.integration.IEDICusresStatusService,
                            private interfaceClientService: interfaces.integration.IInterfaceClientService,
                            private $timeout: ng.ITimeoutService,
                        ) {
                           
                        }

                        GetEnabled(){
                            return  true; // this.Allocation && this.Amount && this.Type && this.Reference1 && !this.disabled;
                        }

                        loadEventTypes(){
                            return this.eventTypeService.getDropdownList().query( (resultList) => {
                                   
                            }).$promise;
                        }

                        loadCusresStatuses(){
                            return this.ediCusresStatusService.getForDropdown().query((resultlist) =>{

                            }).$promise;
                        }

                        loadInterfaceClients(){
                            return this.interfaceClientService.getForDropdown().query((resultlist) =>{

                            }).$promise;
                        }


                  
                        add() { 

                            var referenceId : number =0;
                            var reference : string = "";

                            if (this.cusresStatus)
                            {
                                referenceId = this.cusresStatus.Id;
                                reference = this.cusresStatus.Display;
                            }

                            if (this.interfaceClient)
                            {
                                referenceId = this.interfaceClient.Id;
                                reference = this.interfaceClient.Display;
                            }


                            this.eventUserSubscription = {
                                EventId: this.eventType.Id,
                                Description: this.eventType.Display,
                                ReferenceId: referenceId,
                                Reference: reference,
                                inclusionFilter: this.inclusionFilter,
                                exclusionFilter: this.exclusionFilter,
                                Dasboard: this.dashboardCommunication,
                                Email: this.emailCommunication,
                                RequiresReferenceId: false,
                                includePayload: this.includePayload
                            };

                            this.$uibModalInstance.close(this.eventUserSubscription);
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: { 

                    }
                }).result.then((result  : interfaces.applicationcore.IEventUserSubscriptionDisplay) => {
                   
                    if (result) {

                       

                        (<Array<any>>(this.gvwUserEvents.data)).splice(0, 0, result);

                        this.$timeout(() => {
                            if (this.gridApi) {
                                this.gridApi.rowEdit.setRowsDirty([result]);
                               // this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                            }
                        });

                        this.gridApi.rowEdit.setRowsDirty([result]);
                    }
                });
            }

        };

        angular.module("app").controller("userProfileCtrl", controllers.applicationcore.userProfileCtrl);
    }
}